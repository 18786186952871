import React from "react";
import { Link } from "gatsby";

export default props => (
  <div className="uk-flex uk-flex-center uk-animation-toggle">
    <Link
      to={props.to}
      data-uk-scroll="true"
      className="uk-link-heading uk-position-absolute"
      aria-label="next section"
    >
      <span
        data-uk-icon="icon: chevron-down; ratio: 2"
        className="uk-animation-slide-top"
      />
    </Link>
    <Link
      to={props.to}
      data-uk-scroll="true"
      className="uk-link-heading uk-position-absolute"
      aria-label="next section"
    >
      <span
        data-uk-icon="icon: chevron-down; ratio: 2"
        className="uk-animation-slide-bottom uk-animation-reverse"
      />
    </Link>
    &nbsp;
  </div>
);
