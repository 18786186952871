import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Head from "../components/head"
import Layout from "../components/layout"
import Section from "../components/section"
import HomeServices from "../components/home-services"
import HomeProjects from "../components/home-projects"
import HomeSkills from "../components/home-skills"
import ScrollDown from "../components/scroll-down"

const PageTitle = "Accueil"

export default ({ data }) => (
  <div>
    <Head PageTitle={PageTitle} PageSlug="/" />

    <Layout>
      {/* ====== TAGLINE ====== */}

      <Section
        Style="secondary secondary-gradient uk-padding-small uk-flex uk-flex-bottom uk-text-center"
        Viewport="offset-top: true; offset-bottom: true"
      >

        <div
          // data-uk-scrollspy="cls: uk-animation-slide-bottom-medium;"
          className="uk-width-4-5@s uk-width-2-3@m uk-width-1-2@l uk-text-center uk-light uk-align-center uk-animation-slide-bottom-medium"
        >
          <em>
            <h3>
              <Link to="/services/" className="uk-link-heading">
                “ je vous propose mes services pour développer un outil
                numérique adapté à vos besoins ”
              </Link>
            </h3>
          </em>
        </div>

        <div
          // data-uk-scrollspy="cls: uk-animation-slide-top-medium;"
          className="uk-width-3-5 uk-width-1-3@s uk-width-1-4@m uk-align-center uk-padding uk-animation-slide-top-medium"
        >
          <Link to="/#projects" data-uk-scroll>
            <Img fluid={data.picto.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="uk-margin-large-top uk-margin-small-bottom uk-light">
          <ScrollDown to="/#projects" />
        </div>
      </Section>

      {/* ====== PROJECTS ====== */}

      <Section Style="default uk-padding-small" Id="projects">
        <h2 className="uk-text-center uk-padding uk-margin-remove">
          <Link to="/projects/" className="uk-link-heading">
            Quelques réalisations
          </Link>
        </h2>
      </Section>

      <Section Style="default uk-padding-small uk-padding-remove-top">
        <HomeProjects />
        <div className="uk-flex uk-flex-center uk-margin-large-top uk-margin-medium-bottom">
          <Link
            to="/projects/"
            className="uk-button uk-button-large uk-button-secondary"
          >
            Tous mes projets
          </Link>
        </div>
        <div className="uk-margin-small-bottom">
          <ScrollDown to="/#about" />
        </div>
      </Section>

      {/* ====== ABOUT ====== */}

      <Section Style="muted uk-padding-small" Id="about">
        <h2 className="uk-text-center uk-padding uk-margin-remove">
          <Link to="/about/" className="uk-link-heading">
            Geek de Terrain !
          </Link>
        </h2>
      </Section>

      <Section Style="muted uk-padding-small uk-padding-remove-top">
        <div
          className="uk-grid-collapse uk-child-width-1-2@s uk-flex uk-flex-center uk-flex-middle"
          data-uk-grid
        >
          <div
            data-uk-scrollspy="cls: uk-animation-slide-left-medium;"
            className="uk-width-2-3 uk-width-1-3@s uk-width-1-4@m uk-width-1-5@l"
          >
            <Link to="/about/">
              <Img
                fluid={data.avatar.childImageSharp.fluid}
                className="uk-margin uk-margin-right uk-margin-left uk-border-circle"
              />
            </Link>
          </div>
          <div
            data-uk-scrollspy="cls: uk-animation-slide-right-medium;"
            className="uk-width-2-3@s uk-width-3-4@m uk-width-4-5@l uk-padding-remove uk-text-center"
          >
            <div className="uk-margin-right uk-margin-left">
              <em>
                <h3 className="uk-margin-remove">
                  <Link to="/about/" className="uk-link-heading">
                    “ passionné de technologies, j'aime le travail de terrain et
                    souhaite mettre les outils numériques au service de notre
                    environnement... ”
                  </Link>
                </h3>
              </em>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-center uk-margin-large-top uk-margin-medium-bottom">
          <Link
            to="/about/"
            className="uk-button uk-button-large uk-button-secondary"
          >
            Ma biographie
          </Link>
        </div>
        <div className="uk-margin-large-top uk-margin-small-bottom uk-margin-remove-top">
          <ScrollDown to="/#services" />
        </div>
      </Section>

      {/* ====== SERVICES ====== */}

      <Section Style="secondary uk-light uk-padding-small" Id="services">
        <h2 className="uk-text-center uk-padding uk-margin-remove">
          <Link to="/services/" className="uk-link-heading">
            Principaux services
          </Link>
        </h2>
      </Section>

      <Section Style="secondary uk-light uk-padding-small uk-padding-remove-top">
        <HomeServices />
        <div className="uk-flex uk-flex-center uk-margin-medium-top uk-margin-medium-bottom">
          <Link
            to="/services/"
            className="uk-button uk-button-large uk-button-default"
          >
            Toutes mes prestations
          </Link>
        </div>
        <div className="uk-margin-small-bottom">
          <ScrollDown to="/#footer" />
        </div>
      </Section>

      {/* ====== SKILLS ====== */}

      <Section Style="muted">
        <h2 className="uk-text-center">
          <Link to="/skills/" className="uk-link-heading">
            Techologies favorites
          </Link>
        </h2>
        <HomeSkills />
        <div className="uk-text-center">
          <Link
            to="/skills/"
            className="uk-button uk-button-large uk-button-secondary"
          >
            Toutes les compétences
          </Link>
        </div>
      </Section>
    </Layout>
  </div>
)

export const query = graphql`
  query {
    avatar: file(relativePath: { eq: "pages/avatar-circle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    picto: file(relativePath: { eq: "pages/picto-guslyon-light.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
