import React from "react"
import { graphql } from "gatsby"
import { StaticQuery } from "gatsby"

import Slider from "./slider"
import Card from "./card-title"

const randomSort = arr => arr.sort(() => Math.random() - 0.5)

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: ASC }
            filter: { 
              frontmatter: {
                categoryId: { eq: "skills" }
                homePage: { eq: "true" }
              }
            }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date(formatString: "YYYY")
                  cover_image {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 225) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields {
                  slug
                }
                excerpt(pruneLength: 80)
              }
            }
          }
        }
      `}
      render={data => (
        <Slider Col="4">
          {randomSort(data.allMarkdownRemark.edges)
            .slice(0, 12)
            .map(({ node }) => {
              return (
                <div key={node.id}>
                  <Card
                    ImageFluid={
                      node.frontmatter.cover_image.childImageSharp.fluid
                    }
                    Label=""
                    Title={node.frontmatter.title}
                    LinkUrl={node.fields.slug}
                  />
                </div>
              )
            })}
        </Slider>
      )}
    />
  </div>
)
