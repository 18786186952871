import React from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";

export default props => (
  <div>
    <div
      className="uk-card uk-grid-collapse uk-transition-toggle uk-box-shadow-hover-large uk-flex-middle pointer"
      data-uk-grid
      onClick={() => navigate(props.LinkUrl)}
      onKeyDown={() => navigate(props.LinkUrl)}
      role="link"
      tabIndex="0"
    >
      <div className="uk-card-media-left uk-cover-container uk-width-1-3">
        <div className="uk-transition-scale-up uk-transition-opaque">
          <div className="uk-card-badge uk-label">{props.Label}</div>
          <Img fluid={props.ImageFluid} />
        </div>
      </div>

      <div className="uk-width-2-3 uk-padding-small">
        <h5 className="">{props.Title}</h5>
      </div>
    </div>
  </div>
);
