import React from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";

import Grid from "./grid";
import Card from "./image-title";

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            limit: 6
            sort: { fields: [frontmatter___date], order: ASC }
            filter: { frontmatter: { categoryId: { eq: "services" } } }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  date(formatString: "YYYY")
                  icon {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields {
                  slug
                }
                excerpt(pruneLength: 80)
              }
            }
          }
        }
      `}
      render={data => (
        <Grid Col="3">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <div key={node.id}>
                <Card
                  ImageFluid={node.frontmatter.icon.childImageSharp.fluid}
                  Label=""
                  Title={node.frontmatter.title}
                  LinkUrl={node.fields.slug}
                />
              </div>
            );
          })}
        </Grid>
      )}
    />
  </div>
);
