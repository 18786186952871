import React from "react";
import { Link } from "gatsby";

const loadClass = Col => {
  if (Col === "4") {
    return "uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l";
  } else if (Col === "3") {
    return "uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m";
  } else if (Col === "2") {
    return "uk-slider-items uk-child-width-1-2@s";
  } else {
    return "uk-slider-items";
  }
};

export default props => {
  return (
    <div
      id="serviceSlider"
      data-uk-slider="finite: true; autoplay: true; autoplay-interval: 6000"
      data-uk-scrollspy="cls: uk-animation-slide-bottom-medium"
    >
      <div className="uk-position-relative">
        <div className="uk-slider-container uk-dark">
          <div className="uk-margin-medium-top uk-margin-medium-bottom uk-margin-left uk-margin-right">
            <ul className={loadClass(props.Col)} data-uk-grid>
              {props.children}
            </ul>
          </div>
        </div>

        <div className="uk-hidden@m">
          <Link
            className="uk-position-center-left uk-position-small"
            to="/#"
            datauk-slidenav-previous=""
            data-uk-slider-item="previous"
          />
          <Link
            className="uk-position-center-right uk-position-small"
            to="/#"
            data-uk-slidenav-next=""
            data-uk-slider-item="next"
          />
        </div>

        <div className="uk-visible@m">
          <Link
            className="uk-position-center-left-out uk-position-small"
            to="/#"
            data-uk-slidenav-previous=""
            data-uk-slider-item="previous"
          />
          <Link
            className="uk-position-center-right-out uk-position-small"
            to="/#"
            data-uk-slidenav-next=""
            data-uk-slider-item="next"
          />
        </div>
      </div>

      <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin" />
    </div>
  );
};
