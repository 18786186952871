import React from "react";
import { navigate } from "gatsby";
import Img from "gatsby-image";

export default props => (
  <div>
    <div
      className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large pointer"
      onClick={() => navigate(props.LinkUrl)}
      role="link"
      tabIndex="0"
    >
      <div className="uk-card uk-card-default uk-transition-toggle uk-box-shadow-hover-large">
        <div className="uk-card-media-top uk-cover-container">
          <div className="uk-transition-scale-up uk-transition-opaque">
            <div className="uk-card-badge uk-label">{props.Label}</div>
            <Img fluid={props.ImageFluid} />
          </div>
        </div>
        <div className="uk-card-body uk-padding-small">
          <h5 className="uk-text-truncate uk-text-center">{props.Title}</h5>
        </div>
      </div>
    </div>
  </div>
);
