import React from "react";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";

import Grid from "./grid";
import Card from "./card-title-top";

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            limit: 6
            sort: { fields: [frontmatter___date], order: DESC }
            filter: {
              frontmatter: {
                categoryId: { eq: "projects" }
                homePage: { eq: "true" }
              }
            }
          ) {
            totalCount
            edges {
              node {
                id
                frontmatter {
                  title
                  titleId
                  subtitle
                  date(formatString: "YYYY")
                  cover_image {
                    childImageSharp {
                      fluid(maxWidth: 400, maxHeight: 225) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields {
                  slug
                }
                excerpt(pruneLength: 80)
              }
            }
          }
        }
      `}
      render={data => (
        <Grid Col="3">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <div key={node.id}>
                <Card
                  ImageFluid={
                    node.frontmatter.cover_image.childImageSharp.fluid
                  }
                  Label={node.frontmatter.date}
                  Title={node.frontmatter.title}
                  Subtitle={node.frontmatter.subtitle}
                  // LinkUrl={node.fields.slug}
                  LinkUrl={"/projects/" + node.frontmatter.titleId}
                />
              </div>
            );
          })}
        </Grid>
      )}
    />
  </div>
);
